import { DebouncedFieldInput } from '@/components/DebouncedFieldInput';
import { DebouncedFieldTextArea } from '@/components/DebouncedFieldTextArea';
import FormDividerTitle from '@/components/FormDividerTitle';
import { CloseCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Modal, Row, Tooltip } from 'antd';
import debounce from 'lodash.debounce';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  FieldSelect,
  FieldSwitch,
  formActions,
  useEventCallback,
  validators,
} from 'swuif';
import CountryFieldTwoSide from '../../../components/CountryForm';
import { generateOption } from '@/components/utils';
import {
  ROLE_GENERAL,
  ROLE_TYPE,
  serviceInformation,
  roleInformation,
  SERVICE_LIST,
  ROLE_LEVEL,
  AVAILABLE_ROLE_FOR_SERVICE,
} from '@/config';
import { ReactComponent as RoleIcon } from '@/assets/icon_role_profile.svg';
import { ReactComponent as ServiceIcon } from '@/assets/service-icon.svg';
import { ReactComponent as DeleteIcon } from '@/assets/icon_delete.svg';
import { ReactComponent as SubmitIcon } from '@/assets/icon_submit_white.svg';
import RoleInformationModal from './partial/RoleInformationModal';

function getToolTipTitle(data: any, keyRef: number) {
  return !data[`${keyRef}_service_name`]
    ? 'Please select service name first'
    : '';
}

function getRoleModalWidth(data: any, keyRef: number) {
  return !data[`${keyRef}_service_name`] ||
    data[`${keyRef}_service_name`] === ''
    ? 500
    : 1200;
}

interface RegistrationFormFieldsProps {
  id: number;
  keyRef: number;
  dispatch: React.Dispatch<any>;
  onDeleteService: (key: number) => void;
  countryList: any[];
  countryMap: any;
  container: HTMLElement | undefined;
  availableService: any[];
  setAvailableService: (service: any) => void;
  data: any;
  serviceAdmin: any;
  setApprover: (key: any, countries?: any) => void;
  resetApprover: (key: any) => void;
}

const ServiceFields = ({
  id,
  keyRef,
  dispatch,
  onDeleteService,
  countryList,
  countryMap,
  availableService,
  setAvailableService,
  data,
  serviceAdmin,
  setApprover,
  resetApprover,
}: RegistrationFormFieldsProps) => {
  const [countries, setCountries] = useState<any[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<any[]>([]);
  const [selectedService, setSelectedService] = useState<any>(null);
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const [roleInfoModal, setRoleInfoModal] = useState<boolean>(false);
  const [currRoleInfo, setCurrRoleInfo] = useState<string>('GLOBAL_MANAGER');
  const [serviceInfoModal, setServiceInfoModal] = useState<boolean>(false);
  const [currServiceInfo, setCurrServiceInfo] = useState<string>('assets');

  const generateDeleteService = (index: any, key: any) => {
    if (index === 0) {
      return null;
    }
    return <DeleteIcon onClick={() => onDeleteService(key)} />;
  };

  const generateServiceHeader = (selectedService: any) => {
    if (selectedService) {
      return (
        <>
          <span className="vertical-line-sm"></span>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <ServiceIcon style={{ marginRight: '10px', color: '#313131' }} />
            {SERVICE_LIST[selectedService]}
          </span>
        </>
      );
    }

    return null;
  };

  const generateRoleHeader = (selectedRole: any) => {
    if (selectedRole) {
      return (
        <>
          <span className="vertical-line-sm"></span>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <RoleIcon style={{ marginRight: '10px', color: '#313131' }} />
            {ROLE_GENERAL[selectedRole]}
          </span>
        </>
      );
    }

    return null;
  };

  const generateServiceButton = (selectedService: any) => {
    if (selectedService) {
      return (
        <Button
          className="button-secondary"
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '20px 10px',
          }}
        >
          <span style={{ fontWeight: 'bold' }}>
            {SERVICE_LIST[selectedService]}
          </span>
          <CloseOutlined
            onClick={() => {
              dispatch(formActions.setData(null, `${keyRef}_service_name`));
              dispatch(formActions.setData(null, `${keyRef}_service_role`));
              let avail = availableService;
              avail = avail.filter(a => a !== currServiceInfo);
              avail.push(selectedService);
              setAvailableService(avail);
              setSelectedService(null);
              setSelectedRole(null);
              setServiceInfoModal(!serviceInfoModal);
            }}
          />
        </Button>
      );
    }

    return (
      <Button
        className="button-secondary"
        onClick={() => {
          setServiceInfoModal(!serviceInfoModal);
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '20px 10px',
        }}
      >
        <ServiceIcon style={{ marginRight: '10px', color: 'white' }} />
        <span style={{ fontWeight: 'bold' }}>Select Service</span>
      </Button>
    );
  };

  const generateRoleButton = (selectedRole: any) => {
    if (selectedRole) {
      return (
        <Button
          className="button-secondary"
          onClick={() => {
            setRoleInfoModal(!roleInfoModal);
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '20px 10px',
          }}
        >
          <span style={{ fontWeight: 'bold' }}>
            {ROLE_GENERAL[selectedRole]}
          </span>
          <CloseOutlined
            onClick={() => {
              dispatch(formActions.setData(null, `${keyRef}_service_role`));
              setSelectedRole(null);
            }}
          />
        </Button>
      );
    }

    return (
      <Button
        className="button-secondary"
        onClick={() => {
          setRoleInfoModal(!roleInfoModal);
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '20px 10px',
        }}
      >
        <RoleIcon style={{ marginRight: '10px', color: 'white' }} />
        <span style={{ fontWeight: 'bold' }}>Select Role</span>
      </Button>
    );
  };

  const generateAvailableRoles = (existService: any) => {
    if (!existService || existService === '') {
      return <span>Please select service name first.</span>;
    }

    return (
      <Row className="role-info">
        <Col span={24}>
          <Row style={{ height: '700px' }}>
            <Col
              span={6}
              style={{
                border: '1px solid #e2e2e2',
                borderRight: '0.5px solid #e2e2e2',
              }}
            >
              {AVAILABLE_ROLE_FOR_SERVICE[currServiceInfo].findIndex(item =>
                item.startsWith('GLOBAL'),
              ) > -1 && <div className="header">Global Roles</div>}
              {AVAILABLE_ROLE_FOR_SERVICE[currServiceInfo].indexOf(
                ROLE_TYPE.GLOBAL_MANAGER,
              ) > -1 && (
                <div
                  className={
                    currRoleInfo === 'GLOBAL_MANAGER'
                      ? 'sub-header-active'
                      : 'sub-header'
                  }
                  onClick={() => {
                    setCurrRoleInfo('GLOBAL_MANAGER');
                  }}
                >
                  Global Manager
                </div>
              )}
              {AVAILABLE_ROLE_FOR_SERVICE[currServiceInfo].indexOf(
                ROLE_TYPE.GLOBAL_GENERAL_ADMIN,
              ) > -1 && (
                <div
                  className={
                    currRoleInfo === 'GLOBAL_GENERAL_ADMIN'
                      ? 'sub-header-active'
                      : 'sub-header'
                  }
                  onClick={() => {
                    setCurrRoleInfo('GLOBAL_GENERAL_ADMIN');
                  }}
                >
                  Global General Admin
                </div>
              )}
              {AVAILABLE_ROLE_FOR_SERVICE[currServiceInfo].indexOf(
                ROLE_TYPE.GLOBAL_SERVICE_ADMIN,
              ) > -1 && (
                <div
                  className={
                    currRoleInfo === 'GLOBAL_SERVICE_ADMIN'
                      ? 'sub-header-active'
                      : 'sub-header'
                  }
                  onClick={() => {
                    setCurrRoleInfo('GLOBAL_SERVICE_ADMIN');
                  }}
                >
                  Global Service Admin
                </div>
              )}
              {AVAILABLE_ROLE_FOR_SERVICE[currServiceInfo].indexOf(
                ROLE_TYPE.GLOBAL_VIEWER,
              ) > -1 && (
                <div
                  className={
                    currRoleInfo === 'GLOBAL_VIEWER'
                      ? 'sub-header-active'
                      : 'sub-header'
                  }
                  onClick={() => {
                    setCurrRoleInfo('GLOBAL_VIEWER');
                  }}
                >
                  Global Viewer
                </div>
              )}
              {AVAILABLE_ROLE_FOR_SERVICE[currServiceInfo].findIndex(item =>
                item.startsWith('COUNTRY'),
              ) > -1 && <div className="header">Country Roles</div>}
              {AVAILABLE_ROLE_FOR_SERVICE[currServiceInfo].indexOf(
                ROLE_TYPE.COUNTRY_MANAGER,
              ) > -1 && (
                <div
                  className={
                    currRoleInfo === 'COUNTRY_MANAGER'
                      ? 'sub-header-active'
                      : 'sub-header'
                  }
                  onClick={() => {
                    setCurrRoleInfo('COUNTRY_MANAGER');
                  }}
                >
                  Country Manager
                </div>
              )}
              {AVAILABLE_ROLE_FOR_SERVICE[currServiceInfo].indexOf(
                ROLE_TYPE.COUNTRY_GENERAL_ADMIN,
              ) > -1 && (
                <div
                  className={
                    currRoleInfo === 'COUNTRY_GENERAL_ADMIN'
                      ? 'sub-header-active'
                      : 'sub-header'
                  }
                  onClick={() => {
                    setCurrRoleInfo('COUNTRY_GENERAL_ADMIN');
                  }}
                >
                  Country General Admin
                </div>
              )}
              {AVAILABLE_ROLE_FOR_SERVICE[currServiceInfo].indexOf(
                ROLE_TYPE.COUNTRY_SERVICE_ADMIN,
              ) > -1 && (
                <div
                  className={
                    currRoleInfo === 'COUNTRY_SERVICE_ADMIN'
                      ? 'sub-header-active'
                      : 'sub-header'
                  }
                  onClick={() => {
                    setCurrRoleInfo('COUNTRY_SERVICE_ADMIN');
                  }}
                >
                  Country Service Admin
                </div>
              )}
              {AVAILABLE_ROLE_FOR_SERVICE[currServiceInfo].indexOf(
                ROLE_TYPE.COUNTRY_VIEWER,
              ) > -1 && (
                <div
                  className={
                    currRoleInfo === 'COUNTRY_VIEWER'
                      ? 'sub-header-active'
                      : 'sub-header'
                  }
                  onClick={() => {
                    setCurrRoleInfo('COUNTRY_VIEWER');
                  }}
                >
                  Country Viewer
                </div>
              )}
              {AVAILABLE_ROLE_FOR_SERVICE[currServiceInfo].findIndex(item =>
                item.startsWith('VENUE'),
              ) > -1 && <div className="header">Venue Roles</div>}
              {AVAILABLE_ROLE_FOR_SERVICE[currServiceInfo].indexOf(
                ROLE_TYPE.VENUE_MANAGER,
              ) > -1 && (
                <div
                  className={
                    currRoleInfo === 'VENUE_MANAGER'
                      ? 'sub-header-active'
                      : 'sub-header'
                  }
                  onClick={() => {
                    setCurrRoleInfo('VENUE_MANAGER');
                  }}
                >
                  Venue Manager
                </div>
              )}
              {AVAILABLE_ROLE_FOR_SERVICE[currServiceInfo].indexOf(
                ROLE_TYPE.VENUE_SERVICE_ADMIN,
              ) > -1 && (
                <div
                  className={
                    currRoleInfo === 'VENUE_SERVICE_ADMIN'
                      ? 'sub-header-active'
                      : 'sub-header'
                  }
                  onClick={() => {
                    setCurrRoleInfo('VENUE_SERVICE_ADMIN');
                  }}
                >
                  Venue Service Admin
                </div>
              )}
              {AVAILABLE_ROLE_FOR_SERVICE[currServiceInfo].indexOf(
                ROLE_TYPE.VENUE_VIEWER,
              ) > -1 && (
                <div
                  className={
                    currRoleInfo === 'VENUE_VIEWER'
                      ? 'sub-header-active'
                      : 'sub-header'
                  }
                  onClick={() => {
                    setCurrRoleInfo('VENUE_VIEWER');
                  }}
                >
                  Venue Viewer
                </div>
              )}
            </Col>
            <Col
              span={18}
              style={{
                border: '1px solid #e2e2e2',
                borderLeft: '0.5px solid #e2e2e2',
              }}
            >
              <div
                style={{
                  padding: '30px 40px 30px 40px',
                  position: 'relative',
                }}
              >
                <Button
                  className="button-primary"
                  onClick={() => {
                    setSelectedRole(currRoleInfo);
                    setRoleInfoModal(!roleInfoModal);
                    dispatch(
                      formActions.setData(
                        currRoleInfo,
                        `${keyRef}_service_role`,
                      ),
                    );
                    dispatch(formActions.setData([], `${keyRef}_countries`));
                  }}
                  style={{
                    position: 'absolute',
                    right: '40px',
                    top: '20px',
                    width: '168px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px 10px',
                  }}
                >
                  <SubmitIcon
                    style={{
                      marginRight: '10px',
                      color: 'white',
                    }}
                  />
                  <span style={{ fontWeight: 'bold' }}>Select This Role</span>
                </Button>
                {roleInformation[currRoleInfo].children}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const onChangeAllCountries = useEventCallback((val: any) => {
    if (val) setSelectedCountries(countries.map(country => country.code));
    else setSelectedCountries([]);
  });

  const debouncedChangeAllCountries = useMemo(
    () => debounce(onChangeAllCountries, 250),
    [onChangeAllCountries],
  );

  const onChangeCountryForm = useEventCallback((countries: any[]) => {
    setApprover(keyRef, countries);
    dispatch(formActions.setData(countries, `${keyRef}_countries`));
  });

  const generateApprover = () => {
    let list: any[] = [];
    const roleAdmin = serviceAdmin[selectedService] || {};
    const ROLE_ORDER = [
      ROLE_TYPE.VENUE_MANAGER,
      ROLE_TYPE.COUNTRY_MANAGER,
      ROLE_TYPE.GLOBAL_MANAGER,
      ROLE_TYPE.OPERATION,
    ];
    for (let role of ROLE_ORDER) {
      if (
        data[`${keyRef}_service_role`] &&
        ROLE_LEVEL[role] <= ROLE_LEVEL[data[`${keyRef}_service_role`]]
      ) {
        if (roleAdmin[role]) {
          const admin = roleAdmin[role];
          admin.forEach((adm: any) => {
            let insert = true;

            if (role === ROLE_TYPE.COUNTRY_MANAGER) {
              if (data[`${keyRef}_countries`].length > 0) {
                const adminCountries = adm.countries.split(',');
                const userCountries = data[`${keyRef}_countries`].map(
                  (c: any) => c.code,
                );
                const filteredCountry = userCountries.every((value: any) =>
                  adminCountries.includes(value),
                );
                if (!filteredCountry) insert = false;
              } else insert = false;
            }
            if (insert)
              list.push({
                value: adm.username,
                label: `${adm.username} | ${ROLE_GENERAL[adm.role]}`,
              });
          });
        }
      }
    }
    return list;
  };

  useEffect(() => {
    if (!!data[`${keyRef}_service_name`] && !!data[`${keyRef}_service_role`])
      setApprover(keyRef);
    else resetApprover(keyRef);
  }, [
    data[`${keyRef}_service_name`],
    data[`${keyRef}_countries`],
    data[`${keyRef}_service_role`],
    selectedCountries,
  ]);

  useEffect(() => {
    setCountries(countryList);
  }, [countryList]);

  useEffect(() => {
    setSelectedService((data && data[`${keyRef}_service_name`]) || null);

    let avail = availableService;
    avail = avail
      .filter(a => a)
      .sort((a: string, b: string) => a.localeCompare(b));

    setCurrServiceInfo((data && data[`${keyRef}_service_name`]) || avail[0]);
    setCurrRoleInfo(
      (data && data[`${keyRef}_service_role`]) || 'GLOBAL_MANAGER',
    );
  }, []);

  const { Panel } = Collapse;

  return (
    <Fragment>
      <Collapse
        style={{
          marginBottom: '20px',
          borderRadius: '6px',
          border: '1px solid #767B9B',
        }}
        defaultActiveKey={['1']}
      >
        <Panel
          className="service-panel"
          header={
            <>
              <span>Service Role {id + 1}</span>
              {generateServiceHeader(selectedService)}
              {generateRoleHeader(selectedRole)}
            </>
          }
          key="1"
          extra={generateDeleteService(id, keyRef)}
        >
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row style={{ marginBottom: '20px' }}>
                    <Col
                      span={3}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <span
                        style={{
                          fontFamily: 'Roboto Regular',
                          fontWeight: 'bold',
                          fontSize: '14px',
                          color: '#0A186E',
                        }}
                      >
                        Service <span className="notes-text-asterix">*</span>
                      </span>
                    </Col>
                    <Col
                      span={21}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {generateServiceButton(selectedService)}
                    </Col>
                  </Row>

                  <RoleInformationModal
                    serviceInfoModal={serviceInfoModal}
                    setServiceInfoModal={setServiceInfoModal}
                    currServiceInfo={currServiceInfo}
                    setCurrServiceInfo={setCurrServiceInfo}
                    serviceInformation={serviceInformation}
                    availableService={availableService}
                    selectedService={selectedService}
                    onSubmit={() => {
                      let avail = availableService;
                      avail = avail.filter(a => a !== currServiceInfo);
                      avail.push(selectedService);
                      setAvailableService(avail);
                      setSelectedService(currServiceInfo);
                      setServiceInfoModal(!serviceInfoModal);
                      dispatch(
                        formActions.setData(
                          currServiceInfo,
                          `${keyRef}_service_name`,
                        ),
                      );
                    }}
                  />

                  <Row style={{ marginBottom: '20px' }}>
                    <Col
                      span={3}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <span
                        style={{
                          fontFamily: 'Roboto Regular',
                          fontWeight: 'bold',
                          fontSize: '14px',
                          color: '#0A186E',
                        }}
                      >
                        Role <span className="notes-text-asterix">*</span>
                      </span>
                    </Col>
                    <Col
                      span={21}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {generateRoleButton(selectedRole)}
                    </Col>
                  </Row>

                  <Modal
                    className="modal-service-role"
                    title="Role Information"
                    style={{ borderRadius: '20px' }}
                    width={getRoleModalWidth(data, keyRef)}
                    closable
                    closeIcon={<CloseCircleOutlined />}
                    footer={null}
                    centered
                    visible={roleInfoModal}
                    onCancel={() => {
                      setRoleInfoModal(!roleInfoModal);
                    }}
                  >
                    {generateAvailableRoles(data[`${keyRef}_service_name`])}
                  </Modal>

                  {data[`${keyRef}_service_role`] &&
                    data[`${keyRef}_service_role`].indexOf('COUNTRY') > -1 && (
                      <>
                        <FieldSwitch
                          grid={{ span: 24 }}
                          labelCol={{ sm: 24, md: 24, lg: 24 }}
                          wrapperCol={{ sm: 24, md: 24, lg: 24 }}
                          id={`${keyRef}_for_global_country`}
                          label="For Global Countries"
                          onChange={val => {
                            debouncedChangeAllCountries(val);
                          }}
                        />
                        <Row>
                          <Col className="ant-form-item-label" md={24} lg={24}>
                            Support Countries
                          </Col>
                          <Col md={24} lg={24}>
                            <CountryFieldTwoSide
                              countryList={countries}
                              countryMap={countryMap}
                              selectedCountryList={selectedCountries}
                              onChange={onChangeCountryForm}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                </Col>
              </Row>
              <FormDividerTitle text="Notification" fontSize={14} />
              <Row>
                <Col span={24}>
                  <FieldSelect
                    grid={{ span: 24 }}
                    labelCol={{ sm: 24, md: 24, lg: 24 }}
                    wrapperCol={{ sm: 24, md: 24, lg: 24 }}
                    id={`${keyRef}_approver`}
                    label="Approver"
                    required
                    showSearch
                    getPopupContainer={trigger => trigger.parentElement}
                    notFoundContent={
                      'Please select service name and service role first'
                    }
                    extra="Approver name automatically detected as the default"
                  >
                    {generateOption(generateApprover(), 'value', 'label')}
                  </FieldSelect>
                  <Tooltip title={getToolTipTitle(data, keyRef)}></Tooltip>
                  <DebouncedFieldInput
                    grid={{ span: 24 }}
                    labelCol={{ sm: 24, md: 24, lg: 24 }}
                    wrapperCol={{ sm: 24, md: 24, lg: 24 }}
                    id={`${keyRef}_cc_email`}
                    label="CC Email"
                    validator={validators.pattern(
                      /^(?!.*;).*$/gm,
                      '; character is not allowed',
                    )}
                    extra=" it’s not mandatory. Use this if you want to cc your request to another email address."
                  />
                  <DebouncedFieldTextArea
                    grid={{ span: 24 }}
                    labelCol={{ sm: 24, md: 24, lg: 24 }}
                    wrapperCol={{ sm: 24, md: 24, lg: 24 }}
                    rows={4}
                    id={`${keyRef}_note_email`}
                    label="Note Email"
                    validator={validators.pattern(
                      /^(?!.*;).*$/gm,
                      '; character is not allowed',
                    )}
                    extra="Description"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </Fragment>
  );
};

export default ServiceFields;
